@charset "UTF-8";
/*!
  Theme Name: Musafir Jett App
  Theme URI: https://www.musafir.com/
  Description: The Theme for Musafir Jett App
  Author: Musafir
  Version: 2
*/

@import "fonts";
//@import "variables";
//@import "mixins";
//@import "root";
//@import "reboot";
//@import "type";

:global {
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  article,
  aside,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  main,
  nav,
  section {
    display: block;
  }

  body {
    margin: 0;
    font-family: "Poppins", -system-ui, -apple-system, BlinkMacSystemFont,
      "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji" !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: #212121;
    text-align: left;
    background-color: #ffffff;

    @media (max-width: 575px) {
      padding: 60px 0 0; //$header-height-mbl
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }

  .browsersupport {
    &.dialog {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1300;
      position: fixed;

      & .backdrop {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        position: fixed;
        touch-action: none;
        background-color: #fafbfd;
        -webkit-tap-highlight-color: transparent;
      }

      & .dialog-modal {
        z-index: 1200 !important;
        height: 100%;
        outline: none;
        transform: translate(0px, 0px);
        transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
        display: flex;
        align-items: center;
        justify-content: center;

        & .dialog-box {
          max-width: 600px;
          flex: 0 1 auto;
          max-height: calc(100% - 96px);
          margin: 48px;
          display: flex;
          position: relative;
          overflow-y: auto;
          flex-direction: column;
          box-shadow: 1px 2px 2px 1px #eee;
          border-radius: 4px;
          background-color: #fff;

          @media (max-width: 575px) {
            margin: 16px;
          }

          & .dialog-title {
            flex: 0 0 auto;
            margin: 0;
            padding: 24px 24px 0px;
            line-height: 1.4;
            margin: 0;
            display: block;
            color: rgba(0, 0, 0, 0.87);
            font-weight: 500;
            letter-spacing: 0.0075em;

            h6 {
              margin-bottom: 0.5rem;
              font-size: 16px;
              font-weight: 500;
              line-height: 1.2;
              color: #212121;

              @media (max-width: 575px) {
                font-size: 18px;
              }
            }
          }

          & .dialog-body {
            flex: 1 1 auto;
            padding: 0 24px 24px;
            overflow-y: auto;

            & p {
              line-height: 1.5;
              color: rgba(0, 0, 0, 0.54);
              margin: 0;
              display: block;
              font-size: 1rem;
              letter-spacing: 0.00938em;
              font-weight: 400;
              margin-bottom: 16px;
            }
          }
          & .button {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: auto;
            min-width: 85px;
            height: 40px;
            padding: 6px 12px;
            border-radius: 3px;
            box-shadow: none;
            font-family: "Poppins";
            font-size: 15px;
            font-weight: 500;
            text-decoration: none;
            color: #222;
            background-color: #FFC300;
            cursor: pointer;
            transition: all 0.3s;
            z-index: 999;
            float: right;

            @media (max-width: 575px) {
              padding: 6px 10px;
            }

            &:hover {
              background-color: #FFDA61;
            }
          }
        }

        & .noBrowserSupport {
          max-width: 500px;
          flex: 0 1 auto;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;
          overflow-y: auto;
          margin: 16px;
          text-align: center;
          display: none;

          &__img {
            max-width: 500px;
            & img {
              width: 100%;
            }
            & .something-went-wrong {
              margin-bottom: 20px;
            }
          }
          &__text {
            margin-bottom: 24px;
            h2 {
              font-size: 20px;
              font-weight: normal;
              margin-bottom: 6px;
            }
            p {
              margin: 0;
              padding: 0px 10px;
              font-size: 16px;
            }
          }
          &__checkFieldLabel {
            position: relative;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 24px;
            font-size: 14px;
            line-height: 20px;
          }

          &__checkFieldInput {
            width: 40px;
            @media all and (-ms-high-contrast: none),
              (-ms-high-contrast: active) {
              width: 20px;
            }
            height: 20px;
            cursor: pointer;
            margin: 0px 10px 0px 0px;
            overflow: hidden;

            & input {
              position: absolute;
              opacity: 0;
              height: 0;
              width: 0;
            }

            & span {
              position: absolute;
              width: 18px;
              height: 18px;
              background: transparent;
              border: 1px solid #777777;
              border-radius: 3px;
              z-index: 10;
              transition: all 0.3s;

              &::after {
                content: "";
                position: absolute;
                display: none;
                left: 5px;
                top: 0px;
                width: 7px;
                height: 12px;
                border: solid white;
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
              }
            }

            & input:checked ~ span:after {
              display: block;
            }

            & input:checked ~ span {
              border-color: #0087FA;
              background: #0087FA;
            }
          }
          &__action {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 12px;

            & .button {
              display: inline-flex;
              justify-content: center;
              align-items: center;
              width: auto;
              min-width: 120px;
              height: 40px;
              padding: 6px 12px;
              border-radius: 3px;
              border: none;
              box-shadow: none;
              font-family: "Poppins";
              font-size: 15px;
              font-weight: 500;
              text-decoration: none;
              color: #222;
              background-color: #ffc300;
              cursor: pointer;
              transition: all 0.3s;
              z-index: 999;
              float: right;

              @media (max-width: 575px) {
                padding: 6px 10px;
              }

              &:hover {
                background-color: #ffda61;
              }
            }
          }

          &__link {
            display: inline-block;

            & a {
              color: #0083ff;
              text-decoration: none;
              background-color: transparent;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
